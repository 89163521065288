// extracted by mini-css-extract-plugin
export var ArtistDescription = "ShepardFairey-module--ArtistDescription--TuqI0";
export var ArtistInfos = "ShepardFairey-module--ArtistInfos--YYfKb";
export var ButtonWrapper = "ShepardFairey-module--ButtonWrapper --uUxae";
export var CardWrapper = "ShepardFairey-module--CardWrapper--3LoF-";
export var CarrouselWrapper2 = "ShepardFairey-module--CarrouselWrapper2--DWxIs";
export var Citations = "ShepardFairey-module--Citations--1QqE1";
export var DescriptionWrapper = "ShepardFairey-module--DescriptionWrapper--7zcdw";
export var ImageWrapper = "ShepardFairey-module--ImageWrapper--oaqf5";
export var LinkWrapper = "ShepardFairey-module--LinkWrapper--RBAcF";
export var MobileProtrait = "ShepardFairey-module--MobileProtrait--LEecC";
export var More = "ShepardFairey-module--More--Y8kXc";
export var MoreButton = "ShepardFairey-module--MoreButton--ofL7M";
export var NameWrapper = "ShepardFairey-module--NameWrapper--udnGF";
export var PdpWrapper = "ShepardFairey-module--PdpWrapper--j+bN3";
export var PhotosWrapper = "ShepardFairey-module--PhotosWrapper--coitu";
export var ProfilWrapper = "ShepardFairey-module--ProfilWrapper--lFVeQ";
export var TitleWrapper = "ShepardFairey-module--TitleWrapper--gSSyj";
export var Wrapper = "ShepardFairey-module--Wrapper--JXRDT";